export const customDimensions = () => `
window.aimDataLayer = window.aimDataLayer || [];
function aimTag(){aimDataLayer.push(arguments);}

aimTag("d5d19370-8b14-11ea-940f-238ffaf49ed3", "pageview");
aimTag("d5d19370-8b14-11ea-940f-238ffaf49ed3", "session", function(session) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": "session",
        "session_id": session.id || "",
        "session_timestamp": session.timestamp || ""
    });
});
aimTag("d5d19370-8b14-11ea-940f-238ffaf49ed3", "signal", function(err, json) {
    if (err) {
        console.log(err);
    } else {
        window.dataLayer = window.dataLayer || [];

        if (Object.keys(json).length === 0 || json.identity_type === "UNK") {
            console.log("AIM Signal empty (Not Authenticated)");
        } else {
            window.dataLayer.push({
                "event": "signal",
                "dgid": json.dgid || "",
                "hcp_type": json.hcp_type || "",
                "professional_designation": json.professional_designation || "",
                "specialty": json.dmd_specialty_code || ""
            });
        }
    }
});
`;
export default customDimensions;
