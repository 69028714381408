export const staticPages = {
    "1100": {
        "path": "/aim_tag/baseline.html?env=qa",
        "title": "AIM Tag Baseline [1100]",
    },
    "1200": {
        "path": "/aim_tag/iqvia_hca_sso.html?env=qa",
        "title": "SSO: AIM Tag with HCA [1200]",
    },
    "1210": {
        "path": "/aim_tag/iqvia_hca_sso_stg.html",
        "title": "SSO: AIM Tag with HCA [1210]",
    },
    "1220": {
        "path": "/aim_tag/iqvia_hca_sso_prod.html",
        "title": "SSO: AIM Tag with HCA [1220]",
    },
};
