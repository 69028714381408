export const customDimensions = () => `
window.aimDataLayer = window.aimDataLayer || [];
function aimTag(){aimDataLayer.push(arguments);}

aimTag("d5d19370-8b14-11ea-940f-238ffaf49ed3", "pageview");
aimTag("d5d19370-8b14-11ea-940f-238ffaf49ed3", "session", function (session) {
    // set value for custom dimension at index 5
    ga("set", "dimension5", session.id);
    // trigger GA event to immediately relay custom dimensions
    ga("send", "event", {
        eventCategory: "AIM",
        eventAction: "session",
        eventLabel: "visitor",
        eventValue: session,
        nonInteraction: true
    });
});
aimTag("d5d19370-8b14-11ea-940f-238ffaf49ed3", "signal", function(err, json) {
    if (err) {
        console.log(err);
    } else {
        if (Object.keys(json).length === 0 || json.identity_type === "UNK") {
            console.log("AIM Signal empty (Not Authenticated)");
        } else {
            // set value for custom dimension at index 1
            if(!!json.dgid){ ga("set", "dimension1", json.dgid); }
            // set value for custom dimension at index 2
            if(!!json.dgid){ ga("set", "dimension2", json.hcp_type); }
            // set value for custom dimension at index 3
            if(!!json.specialty){ ga("set", "dimension3", json.dmd_specialty_code); }
            // set value for custom dimension at index 4
            if(!!json.npi_number){ ga("set", "dimension4", json.professional_designation); }
            // trigger GA event to immediately relay custom dimensions
            ga("send", "event", {
                eventCategory: "AIM",
                eventAction: "signal",
                eventLabel: "DGID",
                eventValue: json.dgid,
                nonInteraction: true
            });
        }
    }
});
`;
export default customDimensions;
