import { routes } from "../routes";
import { satelliteTrack } from "../snippets/adobe_analytics/satellite_track";

export const adobeAnalyticsIntegrationPages = [
    {
        "route": routes.html[810],
        "script": satelliteTrack,
    },
    {
        "route": routes.html[811],
        "script": satelliteTrack,
    },
];
