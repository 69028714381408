import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Grid, Header, List, Segment } from "semantic-ui-react";
import { SnippetConfig } from "../../../types/snippet_config";
import { AddScriptTag } from "../../AddScriptTag";
import { install } from "../../../snippets/google_universal_analytics/install";

interface Props {
    config: SnippetConfig;
    modernBuildOnly: boolean;
    script: (host: string) => string;
    title: string;
}

export const GoogleUniversalAnalyticsIntegrationPage = ({
    config,
    modernBuildOnly,
    script,
    title
}: Props): JSX.Element => {

    const noSupport = `\n// No integration for Legacy AIM Reader\n`;
    const scriptText = install(config.googleUniversalAnalytics.apiKey);
    const inlineScriptText = (config.version === "v4") ? script(config.host) : noSupport;
    const completeTitle = `Integration: ${title} (${config.env})`;
    const clientJs = (config.version === "v4") ? `${config.host}/js/client.js?dl=aimDataLayer` : "";
    const clientJsIE = (config.version === "v4") ? `${clientJs}&target=ie11` : "";

    return (
        <Container>
            <HelmetProvider>
                <Helmet>
                    {!modernBuildOnly &&
                        <script async noModule src={clientJsIE}></script>
                    }
                    {!modernBuildOnly &&
                        <script async type="module" src={clientJs}></script>
                    }
                    {modernBuildOnly &&
                        <script async src={clientJs}></script>
                    }
                </Helmet>
            </HelmetProvider>
            <AddScriptTag asyncAttribute={false} script={scriptText} />
            <Header as="h1">{completeTitle}</Header>
            <Grid divided={true}>
                <Grid.Row>
                    <Grid.Column width="13">
                        <Header as="h2">Google Universal Analytics Vendor Library</Header>
                        <Segment style={{ "overflow": "auto", "whiteSpace": "nowrap" }} >
                            <pre data-testid="googleUniversalAnalyticsJsSnippet">
                                {`<script>`}
                                {scriptText}
                                {`</script>`}
                            </pre>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width="3">
                        <Header as="h2">References</Header>
                        <List divided={true} relaxed={true}>
                            {modernBuildOnly &&
                                <List.Item
                                    as="a"
                                    href="/html/integration_examples/google_universal_analytics.html?version=aim_tag"
                                >
                                    <List.Icon name="industry" size="large" verticalAlign="middle" />
                                    <List.Content>
                                        <List.Header>IE11 Build for Google Universal Analytics</List.Header>
                                        <List.Description>AIM Test</List.Description>
                                    </List.Content>
                                </List.Item>
                            }
                            <List.Item
                                as="a"
                                target="google"
                                href="https://developers.google.com/analytics/devguides/collection/analyticsjs"
                            >
                                <List.Icon name="industry" size="large" verticalAlign="middle" />
                                <List.Content>
                                    <List.Header>Google Universal Analytics</List.Header>
                                    <List.Description>Integration Guide</List.Description>
                                </List.Content>
                            </List.Item>
                            <List.Item
                                as="a"
                                target="confluence"
                                href="https://dmdconnects.atlassian.net/wiki/spaces/AIM/pages/3006595085/AIM+Tag+with+Google+Analytics"
                            >
                                <List.Icon name="archive" size="large" verticalAlign="middle" />
                                <List.Content>
                                    <List.Header>DMD Confluence</List.Header>
                                    <List.Description>Internal Docs</List.Description>
                                </List.Content>
                            </List.Item>
                            <List.Item
                                as="a"
                                target="docs"
                                href="https://docs.dmdconnects.com/aim/integration/ga-custom-dimensions"
                            >
                                <List.Icon name="book" size="large" verticalAlign="middle" />
                                <List.Content>
                                    <List.Header>DMD Public Docs</List.Header>
                                    <List.Description>Customer Integration Guide</List.Description>
                                </List.Content>
                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Header as="h2">
                JavaScript Integration
            </Header>
            <Container>
                <p>
                    The integration is managed in scope of the webpage.
                </p>
            </Container>
            <Segment style={{ "overflow": "auto", "whiteSpace": "nowrap" }} >
                <pre data-testid="googleUniversalAnalyticsJsTag">
                    {!modernBuildOnly &&
                        `<script async nomodule src="${clientJsIE}"></script>\n`
                    }
                    {!modernBuildOnly &&
                        `<script async type="module" src="${clientJs}"></script>\n`
                    }
                    {modernBuildOnly &&
                        `<script async src="${clientJs}"></script>\n`
                    }
                    {`<script>`}
                    {inlineScriptText}
                    {`</script>`}
                </pre>
            </Segment>
            <AddScriptTag asyncAttribute={false} script={inlineScriptText} />
        </Container >
    );
};
