export const integrationDefault = (host: string) => `
window.aimDataLayer = window.aimDataLayer || [];
function aimTag(){aimDataLayer.push(arguments);}

aimTag("d5d19370-8b14-11ea-940f-238ffaf49ed3", "pageview");
aimTag("d5d19370-8b14-11ea-940f-238ffaf49ed3", "signal", function(err, success){
    if (err) {
        console.log(err);
    } else {
        console.log(success);
    }
});
`;
export default integrationDefault;
