import { EnvironmentDependent } from "../types/environment_dependent";
import { Environment } from "../types/environment";
import { SnippetConfig } from "../types/snippet_config";

const env: Environment = process.env.REACT_APP_ENV as Environment || "local";

const defaults = {
    "adobeAnalytics": {
        "source": "//assets.adobedtm.com/launch-EN8689d0d2e7d84048be077a460fec531e.min.js",
    },
    "clientCookieSessionKey": "dmd-sid",
    "clientCookieTagKey": "dmd-tag",
    "dmdCookies": {
        "clear": "",
        "read": ""
    },
    "dmdStorage": {
        "clear": "",
        "read": ""
    },
    "localStorageTagKey": "dmd-tag",
    "googleAnalytics4": {
        "apiKey": "G-C1S2W7PS9R",
        "source": "https://www.googletagmanager.com/gtag/js?id=",
    },
    "googleUniversalAnalytics": {
        "apiKey": "UA-207141006-1",
    },
    "gtm": {
        "apiKey": "GTM-5963ZLD",
        "apiKeyModernBuild": "GTM-PW5SN7PC",
    },
    "version": "v4",
};

const test = {
    ...defaults,
    "altApiKey": "0384ef90-831f-11ea-8257-6d4f110cca37",
    "apiKey": "d5d19370-8b14-11ea-940f-238ffaf49ed3",
    "env": "test" as const,
    "gtm": {
        "apiKey": "GTM-KT9TR2C",
        "apiKeyModernBuild": "GTM-KRKQNVVH",
    },
    "host": "//aim-tag.dev.dmd.works",
    "redirects": [],
    "reportViewApiKey": "1-1462-9AE85317",
};

const local = {
    ...defaults,
    "altApiKey": "0384ef90-831f-11ea-8257-6d4f110cca37",
    "apiKey": "d5d19370-8b14-11ea-940f-238ffaf49ed3",
    "env": "local" as const,
    "gtm": {
        "apiKey": "GTM-KT9TR2C",
        "apiKeyModernBuild": "GTM-KRKQNVVH",
    },
    "host": "https://aim-tag.local.hcn.health:8082",
    "reportViewApiKey": "1-1462-9AE85317",
};

const dev = {
    ...defaults,
    "altApiKey": "0384ef90-831f-11ea-8257-6d4f110cca37",
    "apiKey": "d5d19370-8b14-11ea-940f-238ffaf49ed3",
    "env": "dev" as const,
    "host": "https://aim-tag.dev.hcn.health",
    "reportViewApiKey": "1-1462-9AE85317",
};

const sbx = {
    ...defaults,
    "altApiKey": "0384ef90-831f-11ea-8257-6d4f110cca37",
    "apiKey": "d5d19370-8b14-11ea-940f-238ffaf49ed3",
    "env": "sbx" as const,
    "host": "https://aim-tag.sbx.hcn.health",
    "reportViewApiKey": "1-1462-9AE85317",
};

const stg = {
    ...defaults,
    "altApiKey": "0384ef90-831f-11ea-8257-6d4f110cca37",
    "apiKey": "d5d19370-8b14-11ea-940f-238ffaf49ed3",
    "env": "stg" as const,
    "host": "https://aim-tag.stg.hcn.health",
    "reportViewApiKey": "1-1462-9AE85317",
};

const qa = {
    ...defaults,
    "altApiKey": "0384ef90-831f-11ea-8257-6d4f110cca37",
    "apiKey": "d5d19370-8b14-11ea-940f-238ffaf49ed3",
    "env": "qa" as const,
    "host": "https://aim-tag.qa.hcn.health",
    "reportViewApiKey": "1-1462-9AE85317",
};

const uat = {
    ...defaults,
    "altApiKey": "0384ef90-831f-11ea-8257-6d4f110cca37",
    "apiKey": "1-206-E539680D",
    "env": "uat" as const,
    "host": "https://aim-tag.uat.hcn.health",
    "reportViewApiKey": "1-1462-9AE85317",
};

const prod = {
    ...defaults,
    "altApiKey": "0384ef90-831f-11ea-8257-6d4f110cca37",
    "apiKey": "d5d19370-8b14-11ea-940f-238ffaf49ed3",
    "env": "prod" as const,
    "gtm": {
        "apiKey": "GTM-NSRSTNX",
        "apiKeyModernBuild": "GTM-M9F28CM",
    },
    "host": "https://aim-tag.hcn.health",
    "reportViewApiKey": "1-1462-9AE85317",
};

export const config: EnvironmentDependent<SnippetConfig> = {
    "dev": dev,
    "local": local,
    "prod": prod,
    "qa": qa,
    "sbx": sbx,
    "stg": stg,
    "test": test,
    "uat": uat,
};

export default config[env];
