import { routes } from "../routes";
import { gtmAndAppnexus } from "../snippets/prebid/gtm_and_appnexus";
import { gtmAndDeepIntent } from "../snippets/prebid/gtm_and_deepintent";
import { gtmAndPubmatic } from "../snippets/prebid/gtm_and_pubmatic";

export const prebidPages = [
    {
        "route": routes.html[1000],
        "script": gtmAndAppnexus,
    },
    {
        "route": routes.html[1010],
        "script": gtmAndDeepIntent,
    },
    {
        "route": routes.html[1020],
        "script": gtmAndPubmatic,
    },
];
