import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Header, Segment } from "semantic-ui-react";
import { display as gtmDisplay } from "../../../snippets/gtm/display";
import { PrebidConfig } from "../../../types/prebid_config";
import { AddScriptTag } from "../../AddScriptTag";

interface Props {
    config: PrebidConfig;
    script: (targetAdSelector: string, authsApi: string) => string;
    title: string;
}

export const GTMSnippetTestPage = ({ config, script, title }: Props): JSX.Element => {

    const scriptText = script(config.gtm.adSelectors[0], config.authsApi);
    const inlineScriptText = gtmDisplay(config.gtm.adSelectors[0]);
    const completeTitle = `Prebid.js: ${title} (${config.env})`;

    return (
        <Container>
            <HelmetProvider>
                <Helmet>
                    <script async src={config.gtm.source}></script>
                    <script async src={config.prebid}></script>
                </Helmet>
            </HelmetProvider>
            <AddScriptTag script={scriptText} />
            <Header as="h1">{completeTitle}</Header>
            <h5>Test with Prebid public methods</h5>
            <Segment style={{ 'overflow': 'auto', 'whiteSpace': 'nowrap' }} >
                <pre>pbjs.getUserIds();</pre>
                <pre>pbjs.getUserIdsAsEids();</pre>
            </Segment>
            <Segment style={{ 'overflow': 'auto', 'whiteSpace': 'nowrap' }} >
                <pre data-testid="prebidJsSnippet">
                    {`<script async src="${config.gtm.source}"></script>`}
                    <br />
                    {`<script async src="${config.prebid}"></script>`}
                    <br />
                    {`<script>`}
                    {scriptText}
                    {`</script>`}
                    <br />
                    {`<script async src="${config.gtm.source}"></script>`}
                    <br />
                    {`<div id="${config.gtm.adSelectors[0]}">`}
                    {inlineScriptText}
                    {`</div>`}
                </pre>
            </Segment>
            <div id={config.gtm.adSelectors[0]}>
                <AddScriptTag asyncAttribute={false} script={inlineScriptText} />
            </div>
        </Container>
    );
};
