import * as React from 'react';
import { Container, Grid, Header, List, Segment } from 'semantic-ui-react';

class Footer extends React.Component {
    render() {
        return (
            <Segment inverted={true} vertical={true}>
                <Container textAlign="center">
                    <Grid divided={true} inverted={true} stackable={true}>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <Header inverted={true} as="h4" content="Confluence Docs" />
                                <List link={true} inverted={true}>
                                    <List.Item
                                        as="a"
                                        target="_blank"
                                        // tslint:disable-next-line:max-line-length
                                        href="https://dmdconnects.atlassian.net/wiki/spaces/AIM/pages/97124802/A.+Envs+Monitors+Deployers"
                                    >
                                        AIM Web Product
                                    </List.Item>
                                    <List.Item
                                        as="a"
                                        target="_blank"
                                        href="https://dmdconnects.atlassian.net/wiki/spaces/QD/overview"
                                    >
                                        QA Team
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Header inverted={true} as="h4" content="Deployment" />
                                <List link={true} inverted={true}>
                                    <List.Item
                                        as="a"
                                        target="_blank"
                                        href="https://jenkins.dev.dmd.works:8443/view/AIM-Web/job/AIM-Web-Deployer/"
                                    >
                                        DEV Jenkins
                                    </List.Item>
                                    <List.Item
                                        as="a"
                                        target="_blank"
                                        // tslint:disable-next-line:max-line-length
                                        href="https://jenkins.prod.dmd.works:8443/view/AIM%20-%20Web/job/AIM-Web-Deployer/"
                                    >
                                        PROD Jenkins
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Header inverted={true} as="h4" content="Apps" />
                                <List link={true} inverted={true}>
                                    <List.Item
                                        as="a"
                                        target="_blank"
                                        href="https://aim-tag.qa.dmd.works/"
                                    >
                                        AIM Tag
                                    </List.Item>
                                    <List.Item
                                        as="a"
                                        target="_blank"
                                        href="http://aim-toc01.qa.taiga.cloud/"
                                    >
                                        AIM Tag on Click
                                    </List.Item>
                                    <List.Item
                                        as="a"
                                        target="_blank"
                                        href="https://aim-web.qa.dmd.works/"
                                    >
                                        AIM Reader Legacy
                                    </List.Item>
                                    <List.Item
                                        as="a"
                                        target="_blank"
                                        href="http://aim-admin01.qa.taiga.cloud/"
                                    >
                                        AIM Web Admin
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Header inverted={true} as="h4" content="Public Docs" />
                                <List link={true} inverted={true}>
                                    <List.Item
                                        as="a"
                                        target="_blank"
                                        href="http://aim-wiki.dev.dmd.works/"
                                    >
                                        aim-wiki.dev.dmd.works
                                    </List.Item>
                                    <List.Item
                                        as="a"
                                        target="_blank"
                                        href="http://docs.dmdconnects.com"
                                    >
                                        docs.dmdconnects.com
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>
        );
    }
}

export default Footer;
