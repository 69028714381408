const rawBranch = process.env.REACT_APP_GIT_BRANCH || "";
export const versionBranch = rawBranch.slice(0, 3) === "pr-"
    ? rawBranch.slice(3)
    : rawBranch;

export const versionTag = process.env.REACT_APP_GIT_TAG || "";

export const versionHash = process.env.REACT_APP_GIT_SHA || "";

export const version = versionTag.includes("-")
    ? `${versionBranch}#${versionHash}`
    : versionTag;
