export const useStateArray = <T>({
    arrayName = "data",
    createNew,
    setState,
    state,
}: {
    "arrayName"?: string;
    "createNew": (input?: T) => T;
    "setState": (input: { [key: string]: T[] }) => void;
    "state": { [key: string]: T[] } & any;
}) => {

    const data: T[] = state[arrayName] || [];

    const addItem = (index: number = 0, item?: T) => {
        setState({ ...state, [arrayName]: [...data.slice(0, index), createNew(item), ...data.slice(index)] })
    };

    const editItem = (index: number) => (item: T) => {
        setState({ ...state, [arrayName]: [...data.slice(0, index), item, ...data.slice(index + 1)] });
    };
    const editItemValue = ({ index, name, value }: { index: number; name: keyof T; value: T[keyof T] }) =>
        editItem(index)({ ...data[index], [name]: value });

    const removeItem = (index: number) => () => {
        setState({ ...state, [arrayName]: [...data.slice(0, index), ...data.slice(index + 1)] })
    };
    return {
        addItem,
        data,
        editItem,
        editItemValue,
        removeItem,
    };
};
