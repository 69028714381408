import React from "react";
import { Checkbox as SemanticCheckbox, CheckboxProps } from "semantic-ui-react";

export const Checkbox = ({
    label,
    onChange,
    ...props
}: {
    "label": string;
    "onChange": (value: boolean) => void;
} & Omit<CheckboxProps, "onChange">) => {
    return <SemanticCheckbox
        label={label}
        {...props}
        onChange={(event, { checked }) => onChange(Boolean(checked))}
    />;
};
