import * as React from 'react';
import { Container, Header } from 'semantic-ui-react';
import { getGlobalsFromSnippet } from "../../services/get_globals_from_snippet";
import { SnippetConfig } from "../../types/snippet_config";
import { AddScriptTag } from "../AddScriptTag";
import AIMResultsPane from '../AIMResultsPane';
import CodeBox from '../CodeBox';

interface Props {
    config: SnippetConfig;
    script: (config: SnippetConfig) => string;
    title: string;
}

export const ReaderSnippetTestPage = ({ config, script, title }: Props): JSX.Element => {

    const apiKeys = [config.apiKey, config.altApiKey];
    const scriptText = script(config);
    const completeTitle = `Tag Reading: AIM ${config.version} Reader ${title} (${config.env})`;

    return (
        <Container>
            <AddScriptTag script={scriptText} />
            <Header as="h1">{completeTitle}</Header>
            <CodeBox testId="readerSnippet" contents={scriptText} />
            <AIMResultsPane apiKeys={apiKeys} globals={getGlobalsFromSnippet(scriptText)} />
        </Container>
    );
};
