import { routes } from "../routes";
import { integrationDefault } from "../snippets/google_analytics4/integration_default";

export const googleAnalytics4IntegrationPages = [
    {
        "route": routes.html[830],
        "script": integrationDefault,
    },
    {
        "route": routes.html[831],
        "script": integrationDefault,
    },
];
