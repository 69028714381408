import React, { FormEvent, useState } from 'react';
import { Container, Typography, TextField, Button } from "@mui/material";
import { AIMTagIframe } from './html/AIMTagIframe';
import { routes } from '../routes';

interface Props {
    env: string,
    host: string
}

const TagWriting180 = ({env, host}: Props): JSX.Element => {
    const [aimId, setAimId] = useState<string | undefined>(undefined);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const aimId = (e.currentTarget.elements.namedItem('input') as HTMLInputElement)?.value;
        setAimId(aimId);
    };

    return (
        <Container sx={{ fontFamily: "Arial", fontSize: "1.2em", marginBottom: "3em" }}>
            <Typography variant="h4" sx={{ marginBottom: "1em" }}>
                Tag Writing: {routes.html[180].title} ({env})
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    name="input"
                    placeholder="Input AIM ID Value..."
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    sx={{ marginBottom: "1em" }}
                />
                <Button type="submit" variant="contained" color="primary">
                    Set AIM ID
                </Button>
            </form>
            <AIMTagIframe
                aimId={aimId}
                host={host}
                origin={window.location.origin}
            />
        </Container>
    );
};

export default TagWriting180;
