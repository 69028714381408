import * as React from 'react';
import { Container, Header } from 'semantic-ui-react';
import AIMReaderSlimSignalScript from './html/AIMReaderSlimSignalScript';
import { routes } from '../routes';

class SlimSignal410 extends React.Component {
    render() {
        return(
        <Container text={true}>
            <Header as="h1">${routes.html[410].title}</Header>
            <AIMReaderSlimSignalScript />
        </Container>
        );
    }
}

export default SlimSignal410;
