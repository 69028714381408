import { data } from './data';
import { html } from './html';
import { staticPages } from './static';

export const routes = {
    'data': data,
    'html': html,
    'root': '/',
    'static': staticPages,
};
