import React from "react";
import { useFormik } from "formik";
import { Container, TextField, Typography, Box } from "@mui/material";
import partners from "../config/partners";
import { routes } from "../routes";
import { generateUrl } from "../services/tag_on_partner_query_parameter/generate_url";

export const TagWriting130 = ({
  env,
  version,
}: {
  env: string;
  version: string;
}) => {
  const title = `${version} Tag Writing: ${routes.html[130].title} (${env})`;
  const initialValues = {
    "identityType": "dgid",
    "identityValue": "U12345",
    "partnerId": "1",
    "targetUrl": "/html/custom_pageview_modern_build_only.html",
  };

  const { values, handleChange } = useFormik({ initialValues, onSubmit: () => { } });

  const targetUrl = generateUrl({
    "identityType": values.identityType,
    "identityValue": values.identityValue,
    "partnerId": values.partnerId as keyof typeof partners,
    "targetUrl": values.targetUrl,
  });

  return (
    <Container sx={{ "marginBottom": "3em" }}>
      <Typography variant="h3" gutterBottom>{title}</Typography>
      <Box component="form" sx={{ "marginTop": "2em", "marginBottom": "2em", "padding": "2em", "border": "1px solid #333" }}>
        <TextField
          label="Identity Type"
          variant="outlined"
          value={values.identityType}
          onChange={handleChange}
          name="identityType"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Identity Value"
          variant="outlined"
          value={values.identityValue}
          onChange={handleChange}
          name="identityValue"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Partner ID"
          variant="outlined"
          value={values.partnerId}
          onChange={handleChange}
          name="partnerId"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Target Url"
          variant="outlined"
          value={values.targetUrl}
          onChange={handleChange}
          name="targetUrl"
          fullWidth
          margin="normal"
        />
      </Box>
      <Typography variant="body1">
        <a href={targetUrl}>{targetUrl}</a>
      </Typography>
    </Container>
  );
};
