import React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";

const StyledListItem = styled(ListItem)({
  padding: '0.5em 0',
});

const StyledHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const ExampleAuthenticationIDList = () => {
    return (
        <div>     
            <StyledHeader variant="h6" gutterBottom>Examples DGIDs</StyledHeader>
            <List>
                <StyledListItem>U12345 (TST user temporarily unavailable for v2 to v4 testing)</StyledListItem>
                <StyledListItem>U080669619 (TST user for v2 to v4)</StyledListItem>
                <StyledListItem>U084309121 (AUT user)</StyledListItem>
                <StyledListItem>U080734644 (Soft Deleted user)</StyledListItem>
            </List>
            <StyledHeader variant="h6" gutterBottom>Examples Email Campaign IDs</StyledHeader>
            <List>
                <StyledListItem>Active campaign: 999</StyledListItem>
                <StyledListItem>Blocked campaign: 4780 (Merck - User should not authenticate)</StyledListItem>
                <StyledListItem>Blocked campaign: 4908 (Skipta - User should not authenticate)</StyledListItem>
            </List>
        </div>
    );
}

export default ExampleAuthenticationIDList;