import React, { useState } from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { routes } from "../routes";
import aimEmailOpen from "../snippets/aim_email_open";
import CodeBox from "./CodeBox";
import { ExampleAuthenticationIDList } from "./html/ExampleAuthenticationIDList";

interface TagWriting100Props {
  env: string;
  host: string;
  version: string;
}

const Header = styled("h1")(({ theme }) => ({
  fontSize: "2em",
  margin: theme.spacing(1, 0),
}));

const TagWriting100 = ({ env, host, version }: TagWriting100Props): JSX.Element => {
  const [dgid, setDgid] = useState("U12345");
  const [campaignCode, setCampaignCode] = useState("999");
  const [submitted, setSubmitted] = useState(false);

  const handleReset = (event: React.FormEvent) => {
    event.preventDefault();
    window.location.reload();
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const newDgid = form.elements.namedItem("dgid") as HTMLInputElement;
    const newCampaignCode = form.elements.namedItem(
      "campaignCode"
    ) as HTMLInputElement;

    if (newDgid.value.trim() !== "") {
      setDgid(newDgid.value);
    }
    setCampaignCode(newCampaignCode.value);
    setSubmitted(true);
  };

  const scriptText = submitted ? aimEmailOpen(host, { dgid, campaignCode }) : "";

  return (
    <Container data-testid="container">
      <Header data-testid="header">
        {version} Tag Writing: {routes.html[100].title} ({env})
      </Header>
      <form onSubmit={handleSubmit} onReset={handleReset} data-testid="form">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="dgidInput"
              label="dgid"
              name="dgid"
              required
              defaultValue={dgid}
              fullWidth
              data-testid="dgid-input"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="campaignCodeInput"
              label="Campaign Code"
              name="campaignCode"
              defaultValue={campaignCode}
              fullWidth
              data-testid="campaign-code-input"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" disabled={submitted} data-testid="tag-button">
              Tag
            </Button>
            <Button variant="contained" color="secondary" type="reset" style={{ marginLeft: 10 }} data-testid="reset-button">
              Reset Form
            </Button>
          </Grid>
        </Grid>
      </form>
      <Header data-testid="html-header">HTML</Header>
      <CodeBox testId="emailSnippet" data-testid="emailSnippet" contents={scriptText} style={{ marginBottom: '2em' }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Header data-testid="rendered-html-header">Rendered HTML</Header>
          <Box dangerouslySetInnerHTML={{ __html: scriptText }} data-testid="rendered-html-box" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ExampleAuthenticationIDList data-testid="example-authentication-id-list" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TagWriting100;
