export default {
    "1": {
        name: "deepIntent",
        map: {
            "0": "z",
            "1": "I",
            "2": "l",
            "3": "r",
            "4": "P",
            "5": "V",
            "6": "g",
            "7": "6",
            "8": "M",
            "9": "o",
            "a": "D",
            "b": "2",
            "c": "n",
            "d": "5",
            "e": "k",
            "f": "G",
            "g": "p",
            "h": "j",
            "i": "t",
            "j": "c",
            "k": "d",
            "l": "K",
            "m": "S",
            "n": "b",
            "o": "3",
            "p": "Z",
            "q": "x",
            "r": "a",
            "s": "h",
            "t": "Y",
            "u": "4",
            "v": "-",
            "w": "W",
            "x": "B",
            "y": "H",
            "z": "E",
            "A": "e",
            "B": "L",
            "C": "s",
            "D": "i",
            "E": "9",
            "F": "T",
            "G": "F",
            "H": "O",
            "I": "A",
            "J": "8",
            "K": "0",
            "L": "v",
            "M": "N",
            "N": "Q",
            "O": "_",
            "P": "R",
            "Q": "J",
            "R": "w",
            "S": "U",
            "T": "7",
            "U": "y",
            "V": "1",
            "W": "X",
            "X": "u",
            "Y": "m",
            "Z": "f",
            "_": "C",
            "-": "q"
        }
    },
    "2": {
        name: "lasso",
        map: {
            "0": "w",
            "1": "X",
            "2": "S",
            "3": "u",
            "4": "W",
            "5": "P",
            "6": "G",
            "7": "V",
            "8": "3",
            "9": "6",
            "a": "C",
            "b": "E",
            "c": "0",
            "d": "8",
            "e": "g",
            "f": "4",
            "g": "9",
            "h": "R",
            "i": "n",
            "j": "c",
            "k": "F",
            "l": "-",
            "m": "q",
            "n": "A",
            "o": "b",
            "p": "x",
            "q": "D",
            "r": "f",
            "s": "m",
            "t": "I",
            "u": "U",
            "v": "i",
            "w": "d",
            "x": "H",
            "y": "B",
            "z": "Z",
            "A": "_",
            "B": "7",
            "C": "t",
            "D": "a",
            "E": "j",
            "F": "O",
            "G": "v",
            "H": "k",
            "I": "s",
            "J": "5",
            "K": "p",
            "L": "Y",
            "M": "Q",
            "N": "h",
            "O": "1",
            "P": "e",
            "Q": "M",
            "R": "J",
            "S": "l",
            "T": "N",
            "U": "2",
            "V": "K",
            "W": "r",
            "X": "o",
            "Y": "z",
            "Z": "T",
            "_": "L",
            "-": "y"
        }
    }
};
