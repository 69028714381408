export const aimTagWithPageviewOverride = (apiKey: string, dataLayerName = "aimDataLayer") => {
    return `
window.${dataLayerName} = window.${dataLayerName} || [];
function aimTag(){${dataLayerName}.push(arguments);}
aimTag('${apiKey}', 'pageview', { 
    'title': 'Test Title',
    'location': 'https://google.com',
    'referrer': 'https://facebook.com'
});
`
};
