export const satelliteTrack = (host: string) => `
window.aimDataLayer = window.aimDataLayer || [];
function aimTag(){aimDataLayer.push(arguments);}

aimTag("d5d19370-8b14-11ea-940f-238ffaf49ed3", "pageview");
aimTag("d5d19370-8b14-11ea-940f-238ffaf49ed3", "signal", function(err, json) {
    if (err) {
        console.log(err);
    } else {
        window.dataLayer = json;
        _satellite.track("fireLaunchBeacon");
    }
});
`;
export default satelliteTrack;
