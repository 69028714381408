// tslint:disable:max-line-length
export const aimReaderMultiOrgIdWithOrgId = (config: {
    'altApiKey': string,
    'apiKey': string,
    'env': string,
    'host': string,
}) => `
var timestamp = Date.now();

(function(w,d,s,m,n,t){
    w[m]=w[m]||{init:function(){(w[m].q=w[m].q||[]).push(arguments);},ready:function(c){if('function'!=typeof c){return;}(w[m].c=w[m].c||[]).push(c);c=w[m].c;
    n.onload=n.onreadystatechange=function(){if(!n.readyState||/loaded|complete/.test(n.readyState)){n.onload=n.onreadystatechange=null;if(t.parentNode&&n.parentNode){t.parentNode.removeChild(n);}
    while(c.length){(c.shift())();}}};}},w[m].d=1*new Date();n=d.createElement(s);t=d.getElementsByTagName(s)[0];n.async=1;n.src='${config.host}/javascript/beacon.js?'+timestamp;
    ${config.env === 'prod' ? '' : `n.setAttribute("data-sub",'${config.env}');`}n.setAttribute("data-aim",m);t.parentNode.insertBefore(n,t);
})(window,document,'script','AIM_473');

AIM_473.init('${config.apiKey}');

AIM_473.ready(function(){
    AIM_473.ondetect(function(json){
        console.log('AIM_473.ondetect: ', json);
    });
});

(function(w,d,s,m,n,t){
    w[m]=w[m]||{init:function(){(w[m].q=w[m].q||[]).push(arguments);},ready:function(c){if('function'!=typeof c){return;}(w[m].c=w[m].c||[]).push(c);c=w[m].c;
    n.onload=n.onreadystatechange=function(){if(!n.readyState||/loaded|complete/.test(n.readyState)){n.onload=n.onreadystatechange=null;if(t.parentNode&&n.parentNode){t.parentNode.removeChild(n);}
    while(c.length){(c.shift())();}}};}},w[m].d=1*new Date();n=d.createElement(s);t=d.getElementsByTagName(s)[0];n.async=1;n.src='${config.host}/javascript/beacon.js?'+timestamp;
    ${config.env === 'prod' ? '' : `n.setAttribute("data-sub",'${config.env}');`}n.setAttribute("data-aim",m);t.parentNode.insertBefore(n,t);
})(window,document,'script','AIM_508');

AIM_508.init('${config.altApiKey}');

AIM_508.ready(function(){
    AIM_508.ondetect(function(json){
        console.log('AIM_508.ondetect: ', json);
    });
});
`;
// tslint:enable:max-line-length
export default aimReaderMultiOrgIdWithOrgId;
