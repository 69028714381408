import React from "react";
import { Container, Typography, Button, Box, FormControlLabel, Checkbox, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { config as environments } from "../config/environment_switcher";
import { config as versions } from "../config/version_switcher";
import { useQueryParamStore } from "../hooks/use_query_param_store";
import { useStateArray } from "../hooks/use_state_array";
import { routes } from "../routes";
import { getConfig } from "../services/config/get_config";
import { isV2Script } from "../services/type_checks/is_v2_script";
import { Script } from "../types/custom_tests/script";
import { Environment } from "../types/environment";
import { Version } from "../types/version";
import { CustomV2 } from "./CustomV2";
import { CustomV4ModernBuild } from "./CustomV4ModernBuild";

export const TagReading701 = () => {
    const [state, setState] = useQueryParamStore();
    const createNew = ({
        now = Date.now(),
        env = state.env as Environment,
        integration = {},
        version = state.version as Version
    } = {}): Script => ({
        created: String(now),
        env,
        integration,
        version,
    });

    const { addItem, editItem, editItemValue, removeItem, data } = useStateArray<Script>({
        createNew,
        setState,
        state,
    });

    const runScript = state.runScript === "true",
        setRunScript = (value: boolean) => {
            setState({ ...state, runScript: value.toString() });
        };
    const handleVersionChange = (event: SelectChangeEvent<Version>, index: number) => {
        const version = event.target.value;
        const currentEnv = data[index].env;

        if (version && currentEnv) {
            const { host, apiKey } = getConfig(currentEnv, version as Version);
            editItem(index)({
                ...data[index],
                version: version as Version,
                integration: { ...data[index].integration, apiKey, host },
            });
        }
    };

    const handleEnvChange = (event: SelectChangeEvent<Environment>, index: number) => {
        const env = event.target.value as Environment;
        const curVersion = data[index].version;

        if (env && curVersion) {
            const { host, apiKey } = getConfig(env, curVersion);
            editItem(index)({
                ...data[index],
                env: env as Environment,
                integration: { ...data[index].integration, apiKey, host },
            });
        }
    };

    const handleAddItem = (index: number) => {
        addItem(index + 1, data[index]);
    };

    return (
        <Container sx={{ marginBottom: "3em" }} data-testid="mainContainer">
            <Typography variant="h4">{routes.html[701].title}</Typography>
            <Button variant="contained" onClick={() => addItem()} data-testid="addScriptButton">
                Add Script
            </Button>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={runScript}
                        onChange={() => setRunScript(!runScript)}
                        data-testid="runScriptCheckbox"
                    />
                }
                label="Run Script"
            />
            {data.map((item, index) => (
                <Box key={item.created} sx={{ p: 2, m: 2, border: 1 }} data-testid={`boxItem-${index}`}>
                    <Button variant="contained" color="secondary" onClick={removeItem(index)} data-testid={`removeItemButton-${index}`}>
                        X
                    </Button>
                    <Select
                        value={item.version || 'aim_tag'}
                        onChange={(event) => handleVersionChange(event, index)}
                        sx={{ marginLeft: "1em" }}
                        data-testid={`versionSelect-${index}`}
                    >
                        {versions.map((version, i) => (
                            <MenuItem key={i} value={version.value} data-testid={`versionOption-${version.value}`}>
                                {version.text}
                            </MenuItem>
                        ))}
                    </Select>
                    <Select
                        value={item.env || 'qa'}
                        onChange={(event) => handleEnvChange(event, index)}
                        sx={{ marginLeft: "1em" }}
                        data-testid={`envSelect-${index}`}
                    >
                        {environments.map((env, i) => (
                            <MenuItem key={i} value={env.value} data-testid={`envOption-${env.value}`}>
                                {env.text}
                            </MenuItem>
                        ))}
                    </Select>
                    <Button
                        variant="contained"
                        onClick={() => handleAddItem(index)}
                        sx={{ marginLeft: "1em" }}
                        data-testid={`addItemButton-${index}`}
                    >
                        +
                    </Button>
                    {isV2Script(item) ? (
                        <CustomV2
                            env={item.env}
                            integration={item.integration}
                            onChange={(state) => editItemValue({ index, name: "integration", value: state })}
                            runScript={runScript}
                            data-testid={`customV2-${index}`}
                        />
                    ) : (
                        <CustomV4ModernBuild
                            env={item.env}
                            integration={item.integration}
                            onChange={(state) => editItemValue({ index, name: "integration", value: state })}
                            runScript={runScript}
                            data-testid={`customV4-${index}`}
                        />
                    )}
                </Box>
            ))}
        </Container>
    );
};
