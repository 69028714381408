import React from "react";
import { Accordion, AccordionProps } from "semantic-ui-react";

export const AdditionalOptions = ({
    children,
    fluid = true,
    styled = true,
    title = "Additional Options",
    ...props
}: {
    "fluid"?: boolean;
    "styled"?: boolean;
    "title"?: string;
} & AccordionProps) => {
    const additionalOptions = [
        {
            "key": "details",
            "title": title,
            "content": {
                "content": children,
            },
        },
    ];
    return <Accordion {...props} fluid={fluid} panels={additionalOptions} styled={styled} />;
};
