// tslint:disable:max-line-length
export const aimReaderSlim = (config: {
    'apiKey': string,
    'host': string,
}) => `
var postMessageHandler = function(event) {
    console.log("Custom post message handler fired with event data:");
    console.log(event.data);
}

window.addEventListener('message', postMessageHandler, false);

window.onload = function() {
    var element=document.createElement('iframe');
    var timestamp = Date.now();
    var clientOrigin = window.location.origin;
    var query = "?";
    query += "client-origin=" + clientOrigin + "&";
    query += "timestamp=" + timestamp;
    element.src='${config.host}/beacon/portal-lite' + query;
    document.body.appendChild(element);
}
`;
// tslint:enable:max-line-length
export default aimReaderSlim;
