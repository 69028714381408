import * as React from 'react';
import Paper from '@mui/material/Paper';

type CodeBoxProps = { testId: string, contents: string, modified?: boolean, style?: React.CSSProperties };

export const CodeBox = ({testId, contents, modified = false, style = {} }: CodeBoxProps) => (
    <Paper 
        elevation={0}
        sx={{ 
            padding: '1em',
            backgroundColor: '#fff', 
            border: '1px solid rgba(34,36,38,.15)',
            borderRadius: '0.28571429rem',
            color: 'rgba(0,0,0,.87)',
            overflow: 'auto', 
            whiteSpace: 'nowrap',
            ...style 
        }}
    >
        <pre data-testid={testId} data-modified={modified}>
            {contents}
        </pre>
    </Paper>
);
export default CodeBox;