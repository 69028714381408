export const aimTagWithVerifyOnButtonClick = (
    {
        apiKey,
        dataLayerName,
        autoDismissSeconds,
        logoUrl,
        title,
    }: any,
) => {
    return `
window.${dataLayerName} = window.${dataLayerName} || [];
function aimTag(){${dataLayerName}.push(arguments);}

aimTag("${apiKey}", "pageview");

var button = document.getElementById("interactiveElement");
var profile = document.getElementById("interactiveResults");
var verifyOptions = {
    autoDismissSeconds: ${autoDismissSeconds},
    logoUrl: "${logoUrl}",
    title: "${title}"
};

button.addEventListener("click", function (event) {
    button.setAttribute("disabled", true);

    aimTag("${apiKey}", "verify", verifyOptions, function (err, result) {
        if (err) {
            // API Key invalid
            // Follows Signal API Key rules
            button.removeAttribute("disabled")
            profile.innerHTML = [
                "Error:",
                err.message
            ].join("<br />");
        } else {
            renderProfile(result);
        }
    });
});

function renderProfile(result){
    console.log(result);
    // result uses Signal cache
    if (result.identity_type === "UNK") {
        // re-enable verify button only if visitor is unknown
        button.removeAttribute("disabled")
    }
    if (result.email && result.email !== "") {
        // render information on visitor
        profile.innerHTML = [
            profile.innerHTML,
            result.email
        ].join("<br />");
    }
    if (result.first_name && result.first_name !== "") {
        // render information on visitor
        profile.innerHTML = [
            profile.innerHTML,
            result.first_name
        ].join("<br />");
    }
    if (result.last_name && result.last_name !== "") {
        // render information on visitor
        profile.innerHTML = [
            profile.innerHTML,
            result.last_name
        ].join("<br />");
    }
}
`
};
