import axios from 'axios';
import * as React from 'react';
import { Form, TextArea } from 'semantic-ui-react';
import EnvironmentSelector from '../EnvironmentSelector';

// tslint:disable-next-line
class AIMReaderSlimSignalScript extends React.Component<any, any> {

    environmentSelector = new EnvironmentSelector();
    config = this.environmentSelector.getConfig('aim_reader');
    // eslint-disable-next-line
    messageSnippet = "`GET+${apiPath}/${aimId}`";
    // eslint-disable-next-line
    pathSnippet = "`${apiPath}/${aimId}?data=${encodedPayload}&signature=${encodedSignature}`";
    // eslint-disable-next-line
    bodySnippet = "await httpGet(`${apiHost}${path}`)";

    // tslint:disable
    createScriptText() {
        return `
import { createHmac } from "crypto";
import { Request, Response } from "express";
import { AsyncRouter } from "express-async-router";
import * as https from "https";

const router = AsyncRouter();

const httpGet = url => {
    return new Promise((resolve, reject) => {
        https.get(url, (res) => {
            res.setEncoding("utf8");
            let body = ""; 
            res.on("data", chunk => body += chunk);
            res.on("end", () => resolve(body));
        }).on("error", reject);
    });
};

router.get("/", async (req: Request, res: Response) => {

    const apiHost = "${this.config.host}";
    const apiPath = "/api/v1.0/hcp";
    const aimId = 10;
    const key = "sup3rs3cr3t!!";
    const message = ${this.messageSnippet};
    const hmac = createHmac("sha256", key);
    hmac.setEncoding("hex"); 
    hmac.write(message);
    hmac.end(); 

    const signature = hmac.read();
    const timestamp = new Date();
    const payload = JSON.stringify({
        "aimid": aimId, 
        "timestamp": timestamp.getTime()
    });

    const encodedPayload = Buffer.from(payload).toString("base64");
    const encodedSignature = Buffer.from(signature).toString("base64");
    const path = ${this.pathSnippet};
    const body = ${this.bodySnippet};

    res.setHeader("Content-Type", "application/json");
    res.send(JSON.stringify(body, null, 4));
});

export const SlimSignalTesterHandler = router;
        `;
    }

    componentWillMount() {
        axios
            .get('/api/v1/slim-signal-tester')
            .then((result) => {
                console.log(result.data);
            });
    }

    render() {
        return (
            <Form>
                <TextArea autoHeight defaultValue={this.createScriptText()} />
            </Form>
        );
    }
}

export default AIMReaderSlimSignalScript; 
