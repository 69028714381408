import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Header, Grid, List, Segment } from "semantic-ui-react";
import { install } from "../../../snippets/gtm/install";
import { SnippetConfig } from "../../../types/snippet_config";

interface Props {
    config: SnippetConfig;
    script: (host: string) => string;
    title: string;
    links: { publicDoc: string };
}

export const GTMIntegrationPage = ({ config, script, title, links }: Props): JSX.Element => {

    const noSupport = `\n// No integration for Legacy AIM Reader\n`;
    const scriptText = install(config.gtm.apiKey);
    const inlineScriptText = (config.version === "v4") ? script(config.host): noSupport;
    const completeTitle = `Integration: ${title} (${config.env})`;
    const clientJsIE = (config.version === "v4") ? `${config.host}/js/client.js?dl=aimDataLayer&target=ie11` : "";

    return (
        <Container>
            <HelmetProvider>
                <Helmet>
                    <script>{scriptText}</script>
                </Helmet>
            </HelmetProvider>
            <Header as="h1">{completeTitle}</Header>
            <Grid divided={true}>
                <Grid.Row>
                    <Grid.Column width="13">
                        <Header as="h2">GTM Vendor Library</Header>
                        <Segment style={{ "overflow": "auto", "whiteSpace": "nowrap" }} >
                            <pre data-testid="gtmJsSnippet">
                                {`<script>`}
                                {scriptText}
                                {`</script>`}
                            </pre>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width="3">
                        <Header as="h2">References</Header>
                        <List divided={true} relaxed={true}>
                            <List.Item
                                as="a"
                                target="gtm"
                                href="https://tagmanager.google.com"
                            >
                                <List.Icon name="industry" size="large" verticalAlign="middle" />
                                <List.Content>
                                    <List.Header>GTM</List.Header>
                                    <List.Description>GTM Website</List.Description>
                                </List.Content>
                            </List.Item>
                            <List.Item
                                as="a"
                                target="confluence"
                                href="https://dmdconnects.atlassian.net/wiki/spaces/AIM/pages/2997092353/AIM+Tag+with+GTM"
                            >
                                <List.Icon name="archive" size="large" verticalAlign="middle" />
                                <List.Content>
                                    <List.Header>DMD Confluence</List.Header>
                                    <List.Description>Internal Docs</List.Description>
                                </List.Content>
                            </List.Item>
                            <List.Item
                                as="a"
                                target="docs"
                                href={links.publicDoc}
                            >
                                <List.Icon name="book" size="large" verticalAlign="middle" />
                                <List.Content>
                                    <List.Header>DMD Public Docs</List.Header>
                                    <List.Description>Customer Integration Guide</List.Description>
                                </List.Content>
                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Header as="h2">
                JavaScript Integration
            </Header>
            <Container>
                <p>
                    The integration is managed <strong>outside</strong> the scope of the webpage.
                </p>
                <p>
                    Script attributes such as "nomodule" are not permitted. The recommended integration will look similar to this:
                </p>
            </Container>
            <Segment style={{ "overflow": "auto", "whiteSpace": "nowrap" }} >
                <pre data-testid="gtmJsTag">
                    {`<script async src="${clientJsIE}"></script>`}
                    <br />
                    {`<script>`}
                    {inlineScriptText}
                    {`</script>`}
                </pre>
            </Segment>
        </Container>
    );
};
