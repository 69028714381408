
import React, { FormEvent } from 'react';
import { Container, Typography, TextField, Button } from "@mui/material";
import { routes } from '../routes';

const TagWriting142 = (): JSX.Element => {
    const log = console.log;

    const setWindowName = (value: number) => {
        window.name = JSON.stringify({ 'dmd-id': value.toString() });
        log(window.name);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const windowValue = Number((e.currentTarget.elements.namedItem('input') as HTMLInputElement)?.value);
        setWindowName(windowValue);
    };

    return (
        <Container sx={{ fontFamily: "Arial", fontSize: "1.2em", marginBottom: "3em" }}>
            <Typography variant="h4" sx={{ marginBottom: "1em" }}>
                Tag Writing: {routes.html[142].title}
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    name="input"
                    placeholder="Input Window Value..."
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    sx={{ marginBottom: "1em" }}
                />
                <Button type="submit" variant="contained" color="primary">
                    Set Window
                </Button>
            </form>
        </Container>
    );
};

export default TagWriting142;