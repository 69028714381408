import { routes } from '../routes';
import { aimReaderSlim } from '../snippets/aim_reader_slim';
import { SnippetConfig } from "../types/snippet_config";
import { ReaderSnippetTestPage } from './html/ReaderSnippetTestPage';

export const SlimReader400 = (config: SnippetConfig) => ReaderSnippetTestPage({
    config,
    "script": aimReaderSlim,
    "title": routes.html[400].title,
});
