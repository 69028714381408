export const gtmAndPubmatic = (targetAdSelector: string, authsApi: string) => `
var sizes = [
    [300, 250]
];
var PREBID_TIMEOUT = 1000;
var FAILSAFE_TIMEOUT = 3000;

var adUnits = [{
    code: '/19968336/header-bid-tag-1',
    mediaTypes: {
        banner: {
            sizes: sizes
        }
    },
    bids: [{
        bidder: 'pubmatic',
        params: {
            publisherId: '156209'
        }
    }]
}];

var googletag = googletag || {};
googletag.cmd = googletag.cmd || [];
googletag.cmd.push(function() {
    googletag.pubads().disableInitialLoad();
});

var pbjs = pbjs || {};
pbjs.que = pbjs.que || [];

pbjs.que.push(function() {

    // Configure DMD UserID Module
    pbjs.setConfig({
        debug: true,
        cache: {
            url: false
        },
        userSync: {
            userIds: [{
                name: 'dmdId',
                storage: {
                    name: 'dmd-dgid',
                    type: 'cookie',
                    expires: 30
                },
                params: {
                    api_key: 'd5d19370-8b14-11ea-940f-238ffaf49ed3', // provided by DMD
                    api_url: '${authsApi}'
                }
            }]
        }
    });

    pbjs.addAdUnits(adUnits);
    pbjs.requestBids({
        bidsBackHandler: initAdserver,
        timeout: PREBID_TIMEOUT
    });
});

function initAdserver() {
    if (pbjs.initAdserverSet) return;
    pbjs.initAdserverSet = true;
    googletag.cmd.push(function() {
        pbjs.setTargetingForGPTAsync && pbjs.setTargetingForGPTAsync();
        googletag.pubads().refresh();
    });
}

// in case PBJS doesn't load
setTimeout(function() {
    initAdserver();
}, FAILSAFE_TIMEOUT);

googletag.cmd.push(function() {
    googletag.defineSlot('/19968336/header-bid-tag-1', sizes, '${targetAdSelector}')
        .addService(googletag.pubads());
    googletag.pubads().enableSingleRequest();
    googletag.enableServices();
});
`;
export default gtmAndPubmatic;
