import * as React from 'react';
import { Cookies } from 'react-cookie';
import { Checkbox, Container, Header, Form } from 'semantic-ui-react';
import CodeBox from './CodeBox';
import { routes } from '../routes';

class TagDeleting500 extends React.Component<
{
    'clientCookieSessionKey': string,
    'clientCookieTagKey': string,
    'dmdCookies': { 'clear': string, 'read': string },
    'dmdStorage': { 'clear': string, 'read': string },
    'env': string,
    'host': string,
    'localStorageTagKey': string,
    'version': string,
},
{
    'cookies'?: Cookies | null,
    'clientCookieSessionId'?: string | null,
    'clientCookieTagId'?: string | null,
    'localStorageTagId'?: string | null,
    'dmdCookiesRead'?: string | null,
    'dmdStorageRead'?: string | null,
    'windowName'?: string,
}> {
    cookies: Cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.state = {
            clientCookieSessionId: this.cookies.get(this.props.clientCookieSessionKey),
            clientCookieTagId: this.cookies.get(this.props.clientCookieTagKey),
            dmdCookiesRead: `https:${this.props.host}${this.props.dmdCookies.read}`,
            dmdStorageRead: `https:${this.props.host}${this.props.dmdStorage.read}`,
            localStorageTagId: localStorage.getItem(this.props.localStorageTagKey),
            windowName: window.name,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
      }

    handleSubmit = (event: any) => {
        event.preventDefault();
        const clientCookieTag = event.target.elements["clientCookieTag"];
        const localStorageTag = event.target.elements["localStorageTag"];
        const windowName = event.target.elements["windowName"];
        const clientCookieSession = event.target.elements["clientCookieSession"];
        const dmdCookies = event.target.elements["dmdCookies"];
        const dmdStorage = event.target.elements["dmdStorage"];

        if (clientCookieTag.checked) {
            this.cookies.remove(this.props.clientCookieTagKey, { path: '/' });
            this.setState({ clientCookieTagId: null });
        }
        if (localStorageTag.checked) {
            localStorage.removeItem(this.props.localStorageTagKey);
            this.setState({ localStorageTagId: null });
        }
        if (windowName.checked) {
            window.name = "";
            this.setState({ windowName: "" });
        }
        if (clientCookieSession.checked) {
            this.cookies.remove(this.props.clientCookieSessionKey, { path: '/' });
            this.setState({ clientCookieSessionId: null });
        }
        if (dmdCookies.checked) {
            const clearCookiesRequest = new Image();
            const form = this;
            clearCookiesRequest.onload = function(){
                form.setState({ dmdCookiesRead: `https:${form.props.host}${form.props.dmdCookies.read}?${Date.now()}` });
            };
            clearCookiesRequest.src = `https:${this.props.host}${this.props.dmdCookies.clear}?${Date.now()}`;
        }
        if (dmdStorage.checked) {
            const clearStorageRequest = document.createElement("iframe");
            clearStorageRequest.style.display = "none";
            const form = this;
            clearStorageRequest.onload = function(){
                form.setState({ dmdStorageRead: `https:${form.props.host}${form.props.dmdStorage.read}?${Date.now()}` });
            };
            clearStorageRequest.src = `https:${this.props.host}${this.props.dmdStorage.clear}?${Date.now()}`;
            document.body.appendChild(clearStorageRequest);
        }
    }

    render() {
        return (
            <Container text={true}>
                <Header as="h1">{this.props.version} Tag Deleting: {routes.html[500].title} ({this.props.env})</Header>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Field>
                        <Checkbox defaultChecked={true} name="clientCookieTag" label="Delete Client Cookie Tag" />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox defaultChecked={true} name="localStorageTag" label="Delete Client Local Storage Tag" />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox defaultChecked={true} name="windowName" label="Delete Client Window Name" />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox defaultChecked={true} name="clientCookieSession" label="Delete Client Cookie Session" />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox defaultChecked={true} name="dmdCookies" label="Delete DMD Cookies" />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox defaultChecked={true} name="dmdStorage" label="Delete DMD Storage" />
                    </Form.Field>
                    <Form.Button type="submit" data-testid="deleteButton">Delete</Form.Button>
                </Form>
                <Header as="h2">Client Cookie Tag ({this.props.clientCookieTagKey})</Header>
                <CodeBox
                    testId="resultsPane"
                    contents={`${this.state.clientCookieTagId}`}
                />
                <Header as="h2">Client Local Storage Tag ({this.props.localStorageTagKey})</Header>
                <CodeBox
                    testId="resultsPane"
                    contents={`${this.state.localStorageTagId}`}
                />
                <Header as="h2">Client Window Name</Header>
                <CodeBox
                    testId="resultsPane"
                    contents={`${this.state.windowName}`}
                />
                <Header as="h2">Client Cookie Session ({this.props.clientCookieSessionKey})</Header>
                <CodeBox
                    testId="resultsPane"
                    contents={`${this.state.clientCookieSessionId}`}
                />
                <Header as="h2">DMD Cookies</Header>
                <iframe
                    src={`${this.state.dmdCookiesRead}`}
                    title="DMD Cookies"
                    width="100%" />
                <Header as="h2">DMD Storage</Header>
                <iframe
                    src={`${this.state.dmdStorageRead}`}
                    title="DMD Storage"
                    width="100%" />
            </Container >
        );
    }
}

export default TagDeleting500;
