import React, { Fragment, ReactNode } from "react";
import { Button } from "semantic-ui-react";

interface ICollapsibleProps {
    children?: ReactNode;
    style?: any,
}

interface ICollapsibleState {
    isCollapsed: boolean;
}

export class Collapsible extends React.Component<ICollapsibleProps, ICollapsibleState>{
    constructor(props: ICollapsibleProps) {
        super(props);
        this.state = { 'isCollapsed': true };
    }
    render() {
        const { style, children } = this.props;
        const { isCollapsed } = this.state;
        const icon = isCollapsed ? 'add' : 'close';
        return <Fragment>
            <Button
                secondary={true}
                icon={icon}
                style={style}
                onClick={() => { this.setState({ isCollapsed: !isCollapsed }) }}
            />
            {this.state.isCollapsed
                ? null
                : children
            }
        </Fragment>
    }
};
