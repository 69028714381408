import * as React from 'react';
import { Dropdown, Menu, DropdownProps } from 'semantic-ui-react';
import options from '../../config/environment_switcher';
import { config as versions } from '../../config/version_switcher';
import EnvironmentSelector from '../EnvironmentSelector';
import { Environment } from "../../types/environment";
import { Version } from "../../types/version";

class EnvironmentSwitcherSelect extends React.Component {

    environmentSelector = new EnvironmentSelector();

    handleChange = (dropdown: string) =>
        (e: any, { name, value }: DropdownProps) => {
            value = value as string || "";
            if (dropdown === 'environment') {
                this.environmentSelector.setEnvironment(value as Environment);
            }
            if (dropdown === 'version') {
                this.environmentSelector.setVersion(value as Version);
            }

            window.location.reload();
        }

    render() {
        return (
            <Menu>
                <Dropdown
                    selection={true}
                    options={versions}
                    name="versionSwitcher"
                    defaultValue={this.environmentSelector.getVersion()}
                    selectOnNavigation={true}
                    onChange={this.handleChange('version')}
                />
                <Dropdown
                    selection={true}
                    options={options}
                    name="environmentSwitcher"
                    defaultValue={this.environmentSelector.getEnvironment()}
                    selectOnNavigation={true}
                    onChange={this.handleChange('environment')}
                />
            </Menu>
        );
    }
}
export default EnvironmentSwitcherSelect;
