import React, { Fragment } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { useStateArray } from "../hooks/use_state_array";
import { getConfig } from "../services/config/get_config";
import { commandTypes, v4CommandTypes } from "../services/v4_commands/command_types";
import { aimTagClient } from "../snippets/aim_tag_client";
import { aimTagWithNoCommands } from "../snippets/aim_tag_with_no_commands";
import { v4Command } from "../types/custom_tests/v4_command";
import { v4Integration } from "../types/custom_tests/v4_integration";
import { Environment } from "../types/environment";
import { AddScriptTag } from "./AddScriptTag";
import { CodeBox } from "./CodeBox";
import { AdditionalOptions } from "./form/additional_options";
import { Dropdown } from "./form/dropdown";
import { TextInput } from "./form/text_input";

const defaultParams: { [key in v4CommandTypes]?: string } = {
    "session": "console.log",
    "signal": `function(err, success) {if (err) {console.log(err);} else {console.log(success);}}`,
    "pageview": `{'title': document.title,'location': location.href,'referrer': document.referrer}`,
    "transition": undefined,
    "authenticate": `{'email': 'foo@bar.com'}`,
    "impersonate": `'U12345'`,
    "clear": undefined,
};

const hasParams = (type: string) => commandTypes.includes(type) && defaultParams[type] !== undefined;

export const CustomV4 = ({
    env,
    "integration": {
        apiKey = getConfig(env, "aim_tag").apiKey,
        commands = [],
        dataLayerName = "aimDataLayer",
        host = getConfig(env, "aim_tag").host,
        integrityModern,
        integrityIE
    } = getConfig(env, "aim_tag"),
    onChange,
    runScript,
}: {
    'env': Environment,
    'integration'?: v4Integration;
    'onChange': (state: v4Integration) => void;
    'runScript': Boolean,
}) => {
    const state = { apiKey, commands, dataLayerName, host, integrityModern, integrityIE };

    const updateState = (name: keyof typeof state) => (value: (typeof state)[keyof typeof state]) =>
        onChange({ ...state, [name]: value });

    const clientScript = aimTagClient(host || "", dataLayerName || "");
    const clientLegacyScript = clientScript + "&target=ie11";
    let legacyScriptWithTag = `<script async nomodule src="${clientLegacyScript}"`;
    let crossOriginIE, crossOriginModern = undefined;
    if (integrityIE) {
        legacyScriptWithTag += ` integrity="${integrityIE}"`;
        legacyScriptWithTag += ` crossOrigin="anonymous"`;
        crossOriginIE = "anonymous";
    }
    legacyScriptWithTag += `></script>`;

    let clientScriptWithTag = `<script async type="module" src="${clientScript}"`;
    if (integrityModern) {
        clientScriptWithTag += ` integrity="${integrityModern}"`;
        clientScriptWithTag += ` crossOrigin="anonymous"`;
        crossOriginModern = "anonymous";
    }
    clientScriptWithTag += `></script>`;

    const baseIntegration = aimTagWithNoCommands(apiKey, dataLayerName);
    const commandIntegrations = commands
        .map(({ apiKey = state.apiKey, type, params }) => `aimTag('${apiKey}', '${type}'${params ? `, ${params}` : ""})`)
        .join("\n");
    const integration = [baseIntegration, commandIntegrations].join("\n");
    const integrationWithTag = `<script>${integration}\n</script>`;
    const scriptPreview = legacyScriptWithTag + "\n" + clientScriptWithTag + "\n" + integrationWithTag;
    const createNew = ({
        now = Date.now(),
        "apiKey": api = apiKey,
        type = "signal" as v4CommandTypes,
        params = defaultParams[type],
    }: {
        "now"?: number;
        "apiKey"?: string;
        "type"?: v4CommandTypes;
        "params"?: string;
    } = {}): v4Command => ({
        "apiKey": api,
        "created": String(now),
        params,
        type
    });
    const { addItem, editItemValue, removeItem, data } = useStateArray<v4Command>({
        "arrayName": "commands",
        createNew,
        "setState": (input) => onChange({
            ...input,
            "commands": input.commands.map((command, index) => ({
                ...command,
                ...{
                    "params": !hasParams(command.type)
                        ? undefined
                        : state.commands[index]?.type === command.type
                            ? command.params
                            : defaultParams[command.type],
                },
            })),
        }),
        "state": state,
    });
    return <Container>
        <AdditionalOptions data-testid="customV4-Options">
            <TextInput label="API Key" value={apiKey} onChange={updateState("apiKey")} />
            <TextInput label="Data Layer Name" value={dataLayerName} onChange={updateState("dataLayerName")} />
            <TextInput label="Host" value={host} onChange={updateState("host")} />
            <TextInput label="Integrity Modern Browsers" value={integrityModern || ''} onChange={updateState("integrityModern")} />
            <TextInput label="Integrity IE" value={integrityIE || ''} onChange={updateState("integrityIE")} />
            <Button variant="contained" onClick={() => addItem()}>Add Command</Button>
            {data.map((item, index) => {
                return <Paper key={item.created} elevation={3}>
                    <Button variant="contained" color="secondary" onClick={removeItem(index)}>X</Button>
                    <Dropdown
                        options={commandTypes}
                        name={`type[${index}]`}
                        defaultValue={item.type}
                        onChange={(value) => editItemValue({ index, "name": "type", value })}
                    />
                    <TextInput
                        defaultValue={apiKey}
                        label="API Key"
                        onChange={(value) => editItemValue({ index, "name": "apiKey", value })}
                    />
                    {hasParams(item.type) && <TextInput
                        label="Params"
                        onChange={(value) => editItemValue({ index, "name": "params", value })}
                        value={item.params || ""}
                    />}
                    <Button variant="contained" color="primary" onClick={() => addItem(index + 1, item)}>+</Button>
                </Paper>;
            })}
        </AdditionalOptions>
        {runScript && <Fragment>
            <AddScriptTag src={clientLegacyScript} integrity={integrityIE} noModule={true} crossOrigin={crossOriginIE} />
            <AddScriptTag src={clientScript} integrity={integrityModern} typeModule={true} crossOrigin={crossOriginModern} />
            <AddScriptTag asyncAttribute={false} script={integration} />
        </Fragment>}
        <CodeBox testId="readerSnippet" contents={scriptPreview} />
        {/* {runScript && <AIMResultsPane apiKeys={[apiKey]} globals={getGlobalsFromSnippet(scriptText)} />} */}
    </Container>;
};
